module.exports = {
  messages: {
    BUTTON_SHOW_MORE: 'Show more',
    CAREER_GUIDANCE_DETAILS_PAGE_MODERN_1:
      "Do you like solving tasks on your own without any constraints? You'll feel right at home in a modern corporate culture.",
    CAREER_GUIDANCE_DETAILS_PAGE_TRADITIONAL_1:
      'A more traditional corporate culture typically has clearly defined rules and structures to ensure stability and performance.',
    COMMBOX_AUDIENCE_PRIVATE: 'Private (XING contacts only)',
    COMMBOX_AUDIENCE_PRIVATE_DESC: "Your post can't be shared.",
    COMMBOX_AUDIENCE_PRIVATE_SELECTED: 'Private',
    COMMBOX_AUDIENCE_PUBLIC: 'Public',
    COMMBOX_AUDIENCE_PUBLIC_DESC: 'Anyone can see and share this.',
    COMPANY_BENEFIT_ACCESSIBILITY: 'Accessibility',
    COMPANY_BENEFIT_CANTEEN: 'Canteen',
    COMPANY_BENEFIT_CAR_PARK: 'Car park',
    COMPANY_BENEFIT_CHILDCARE: 'Childcare',
    COMPANY_BENEFIT_COMPANY_CAR: 'Company car',
    COMPANY_BENEFIT_COMPANY_DOCTOR: 'Company doctor',
    COMPANY_BENEFIT_COMPANY_PENSION: 'Company pension',
    COMPANY_BENEFIT_CONVENIENT_TRANSPORT_LINKS: 'Convenient transport links',
    COMPANY_BENEFIT_DISCOUNTS_SPECIAL_OFFERS: 'Employee benefits',
    COMPANY_BENEFIT_DOGS_WELCOME: 'Dogs welcome',
    COMPANY_BENEFIT_EVENTS_FOR_EMPLOYEES: 'Staff events',
    COMPANY_BENEFIT_FLEXITIME: 'Flexitime',
    COMPANY_BENEFIT_HEALTH_IN_THE_WORKPLACE: 'Health in the workplace',
    COMPANY_BENEFIT_HOME_OFFICE: 'Work from home',
    COMPANY_BENEFIT_MOBILE_DEVICE: 'Mobile device',
    COMPANY_BENEFIT_PRIVATE_INTERNET_USE: 'Private internet use',
    COMPANY_BENEFIT_PROFIT_SHARING: 'Profit-sharing',
    COMPANY_BENEFIT_RESTAURANT_TICKETS: 'Restaurant tickets',
    COMPANY_BENEFIT_TRAINING: 'Training',
    DUPLICATE_SHARE_ERROR:
      'You already shared this so your contacts are in the loop.',
    JOB_POSTING_APPLY_EXTERNAL_URL: 'Visit employer website',
    JOBS_CREATE_SEARCH_ALERT: 'Create search alert',
    JOBS_DETAIL_MENU_BUTTON_A11Y: 'Select an option',
    JOBS_ERROR_SOMETHING_WENT_WRONG:
      'Sorry, something went wrong. Please try again later.',
    JOBS_FIND_JOBS_LINK: 'Find jobs',
    JOBS_HIGHLIGHT_ACTIVELY_RECRUITING: 'Actively recruiting',
    JOBS_HIGHLIGHT_EARLY_APPLICANT: 'Be an early applicant',
    JOBS_HIGHLIGHT_INSTANT_XING_APPLY: 'Apply without documents',
    JOBS_HIGHLIGHT_LAST_CHANCE_TO_APPLY: 'Last chance to apply',
    JOBS_HYBRID_TAG: 'Hybrid',
    JOBS_JOB_DETAIL_CREATE_SEARCH_ALERT_CTA: 'Create search alert',
    JOBS_JOB_DETAIL_CREATE_SEARCH_ALERT_KEYWORDS:
      'Receive e-mails for new {keywords} jobs:',
    JOBS_JOB_DETAIL_CREATE_SEARCH_ALERT_LOCATION:
      "Don't miss out on new jobs like this in {location}",
    JOBS_JOB_DETAIL_SALARY_ESTIMATED_AVERAGE_LEGEND:
      'Estimated average salary for similar positions',
    JOBS_JOB_DETAIL_SALARY_ESTIMATED_HEADLINE: 'Salary forecast',
    JOBS_JOB_DETAIL_SALARY_ESTIMATED_INFO: 'How forecasts are calculated',
    JOBS_JOB_DETAIL_SALARY_ESTIMATED_INFO_CONTENT:
      "The salary details displayed are a forecast for annual gross salaries in similar positions, including any variable components for full-time workers. These non-binding forecasts are based on various statistical methods which draw upon anonymised XING user data and market data. The following variables influence the salary details range: the title of the job ad, the employer's location, the number of employees, the industry and career level of similar positions (provided XING has such data available). The salary range displayed is indicative of typical salary data and does not include statistical outliers. These salary details may differ from the salaries paid by the job poster.",
    JOBS_JOB_DETAIL_SALARY_ESTIMATED_RANGE_LEGEND:
      'Estimated salary range for similar positions',
    JOBS_JOB_DETAIL_SALARY_EXACT_BODY: 'Provided by the company',
    JOBS_JOB_DETAIL_SALARY_EXACT_HEADLINE: 'Salary',
    JOBS_JOB_DETAIL_SALARY_RANGE_BODY: 'Provided by the company',
    JOBS_JOB_DETAIL_SALARY_RANGE_HEADLINE: 'Salary range',
    JOBS_JOB_DETAILS_ABOUT_COMPANY_HEADER: 'About the company',
    JOBS_JOB_DETAILS_APPLY: 'Apply',
    JOBS_JOB_DETAILS_APPLY_MAIL_BODY:
      "Dear Sir/Madam,\n\nI'd like to apply for the position of {posting_function} advertised on XING.\n\nKind regards,\n{name}",
    JOBS_JOB_DETAILS_APPLY_MAIL_SUBJECT: 'Application: {posting_function}',
    JOBS_JOB_DETAILS_APPLY_MAIL_SUBJECT_WITH_CODE:
      'Application: {posting_function} | Reference: {posting_job_code}',
    JOBS_JOB_DETAILS_CANCEL: 'Cancel',
    JOBS_JOB_DETAILS_COMPANY_ALL_JOBS: 'All job offers',
    JOBS_JOB_DETAILS_COMPANY_CONTACTS_MODULE_SUBTITLE:
      'Find out by asking your contacts:',
    JOBS_JOB_DETAILS_COMPANY_CONTACTS_MODULE_TITLE: 'Good employer?',
    JOBS_JOB_DETAILS_COMPANY_CONTACTS_SHOW_ALL: 'Show all',
    JOBS_JOB_DETAILS_COMPANY_CULTURE_ASSESSMENT_CTA_BODY_COPY:
      'Start assessment',
    JOBS_JOB_DETAILS_COMPANY_CULTURE_ASSESSMENT_CTA_HEADER:
      'Which employers are right for you?',
    JOBS_JOB_DETAILS_COMPANY_CULTURE_ASSESSMENT_CTA_META:
      'Take the culture assessment now to find out.',
    JOBS_JOB_DETAILS_COMPANY_CULTURE_COMPASS_LEGEND_LEFT:
      '<top>Traditional</top>culture',
    JOBS_JOB_DETAILS_COMPANY_CULTURE_COMPASS_LEGEND_RIGHT:
      '<top>Modern</top>culture',
    JOBS_JOB_DETAILS_COMPANY_CULTURE_INFO_COMPASS_INDUSTRY_BALANCED:
      ' The industry average is more of a balance between traditional and modern.',
    JOBS_JOB_DETAILS_COMPANY_CULTURE_INFO_COMPASS_INDUSTRY_IN_LINE:
      ' This is in line with the industry average.',
    JOBS_JOB_DETAILS_COMPANY_CULTURE_INFO_COMPASS_INDUSTRY_NOT_BALANCED:
      ' The industry average {verb} {industryWeight} towards being {cultureBinaryIndustry}.',
    JOBS_JOB_DETAILS_COMPANY_CULTURE_INFO_COMPASS_INDUSTRY_VERB_1: 'tends',
    JOBS_JOB_DETAILS_COMPANY_CULTURE_INFO_COMPASS_INDUSTRY_VERB_2: 'tends',
    JOBS_JOB_DETAILS_COMPANY_CULTURE_INFO_COMPASS_INDUSTRY_VERB_3: 'tends',
    JOBS_JOB_DETAILS_COMPANY_CULTURE_INFO_COMPASS_INDUSTRY_VERB_4: 'gravitates',
    JOBS_JOB_DETAILS_COMPANY_CULTURE_INFO_COMPASS_INDUSTRY_WEIGHT_LIGHT:
      'slightly',
    JOBS_JOB_DETAILS_COMPANY_CULTURE_INFO_COMPASS_INDUSTRY_WEIGHT_STRONG:
      'strongly',
    JOBS_JOB_DETAILS_COMPANY_CULTURE_INFO_COMPASS_INTRO:
      "‪{totalCultureReviews}‬ employees at ‪{companyName}‬ have rated their company's corporate culture",
    JOBS_JOB_DETAILS_COMPANY_CULTURE_INFO_COMPASS_PROFILE_BALANCED:
      ' as a balance between traditional and modern.',
    JOBS_JOB_DETAILS_COMPANY_CULTURE_INFO_COMPASS_PROFILE_BINARY:
      ' as being {profileWeight} {cultureBinaryProfile}.',
    JOBS_JOB_DETAILS_COMPANY_CULTURE_INFO_COMPASS_PROFILE_WEIGHT_LIGHT:
      'slightly',
    JOBS_JOB_DETAILS_COMPANY_CULTURE_INFO_COMPASS_PROFILE_WEIGHT_STRONG: 'very',
    JOBS_JOB_DETAILS_COMPANY_CULTURE_INFO_COMPASS_VALUE_MODERN: 'modern',
    JOBS_JOB_DETAILS_COMPANY_CULTURE_INFO_COMPASS_VALUE_TRADITIONAL:
      'traditional',
    JOBS_JOB_DETAILS_COMPANY_CULTURE_LEGEND_INDUSTRY: 'Industry average',
    JOBS_JOB_DETAILS_COMPANY_CULTURE_TITLE: 'Corporate culture',
    JOBS_JOB_DETAILS_DEFAULT_ERROR: 'Something went wrong. Please try again.',
    JOBS_JOB_DETAILS_DESCRIPTION: 'Job description',
    JOBS_JOB_DETAILS_DESCRIPTION_HEADLINE: 'About this job',
    JOBS_JOB_DETAILS_DESCRIPTION_SHOW_LESS: 'Show less',
    JOBS_JOB_DETAILS_DESCRIPTION_SHOW_MORE: 'Show more',
    JOBS_JOB_DETAILS_EMPLOYEE_REVIEW: 'Based on {ratingCount} reviews',
    JOBS_JOB_DETAILS_EMPLOYEE_REVIEWS_TITLE: 'Overall rating',
    JOBS_JOB_DETAILS_EMPLOYER_SHOWCASE_VIDEO: 'Employer showcase video',
    JOBS_JOB_DETAILS_EXTERNAL_JOB_BANNER_BODY:
      'This job is listed outside of XING. But we can take you there.',
    JOBS_JOB_DETAILS_EXTERNAL_JOB_BANNER_CTA: 'View job',
    JOBS_JOB_DETAILS_EXTERNAL_JOB_BANNER_TITLE: 'Want to know more?',
    JOBS_JOB_DETAILS_EXTERNAL_URL_DESCRIPTION_NO_CONSENT_HEADLINE:
      "This job ad is served directly from an external website, and you've deactivated such content on XING in your settings (privacy > tracking).",
    JOBS_JOB_DETAILS_EXTERNAL_URL_DESCRIPTION_NO_CONSENT_LINK:
      'But you can still {link}.',
    JOBS_JOB_DETAILS_EXTERNAL_URL_DESCRIPTION_NO_CONSENT_LINK_CONTENT:
      'view the listing on the host website',
    JOBS_JOB_DETAILS_FOLLOW_COMPANY: 'Follow',
    JOBS_JOB_DETAILS_JOB_CONTACT_CURRENT_COMPANY: 'Works at {company}',
    JOBS_JOB_DETAILS_JOB_CONTACT_PREVIOUS_COMPANY: 'Used to work at  {company}',
    JOBS_JOB_DETAILS_KUNUNU_BENEFITS: 'Employee benefits',
    JOBS_JOB_DETAILS_KUNUNU_BENEFITS_SHOW_ALL: 'View all benefits',
    JOBS_JOB_DETAILS_KUNUNU_BENEFITS_SHOW_LESS: 'Show fewer benefits',
    JOBS_JOB_DETAILS_KUNUNU_CULTURE: 'Show more',
    JOBS_JOB_DETAILS_KUNUNU_RATED_BY: 'Rated by {rating} employees',
    JOBS_JOB_DETAILS_KUNUNU_TITLE: 'Employer reviews',
    JOBS_JOB_DETAILS_MANAGE: 'Manage job ad',
    JOBS_JOB_DETAILS_MESSAGE_HR_CONTACT: 'Message',
    JOBS_JOB_DETAILS_NEXT_JOB: 'Next job',
    JOBS_JOB_DETAILS_NEXT_SIMILAR_JOB: 'Next similar job',
    JOBS_JOB_DETAILS_PREVIOUS_JOB: 'Previous job',
    JOBS_JOB_DETAILS_PREVIOUSLY_APPLIED_CONFIRMATION:
      'You applied for this job on {date}.',
    JOBS_JOB_DETAILS_REPORT_JOB: 'Report job ad',
    JOBS_JOB_DETAILS_REPORT_JOB_ADD_COMMENT: 'Add a comment (optional)',
    JOBS_JOB_DETAILS_REPORT_JOB_BUTTON: 'Report',
    JOBS_JOB_DETAILS_REPORT_JOB_CAREER_LEVEL: 'Wrong career level',
    JOBS_JOB_DETAILS_REPORT_JOB_COMMENT_PLACEHOLDER:
      "Let us know what's wrong with this job ad.",
    JOBS_JOB_DETAILS_REPORT_JOB_COMPANY_NAME: 'Wrong company name',
    JOBS_JOB_DETAILS_REPORT_JOB_DESCRIPTION:
      'Reporting suspicious job ads helps us improve the quality on XING Jobs.',
    JOBS_JOB_DETAILS_REPORT_JOB_EMPLOYMENT_TYPE: 'Wrong job type',
    JOBS_JOB_DETAILS_REPORT_JOB_ILLEGAL_CONTENT: 'Illegal content',
    JOBS_JOB_DETAILS_REPORT_JOB_LOCATION: 'Wrong location',
    JOBS_JOB_DETAILS_REPORT_JOB_NO_JOB_AD: 'Not a job ad',
    JOBS_JOB_DETAILS_REPORT_JOB_OTHER: 'Other content',
    JOBS_JOB_DETAILS_REPORT_JOB_TITLE: 'Report job ad',
    JOBS_JOB_DETAILS_REPORT_JOB_WRONG_INFORMATION:
      'Inaccurate content (select at least one option)',
    JOBS_JOB_DETAILS_REPORTED_JOB: 'Job ad reported',
    JOBS_JOB_DETAILS_SE_DEFAULT_PAGETITLE: 'Search results in jobs',
    JOBS_JOB_DETAILS_SE_DEFAULT_PAGETITLE_SUFFIX: 'XING Search',
    JOBS_JOB_DETAILS_SEO_META_DESCRIPTION_TAG_POSTING_DETAIL:
      "Apply for the position of ''{function}'' at {company_name} in {city}. Industry: {industry} / Job type: {job_type} / Career level: {level} / Posted on: {date}",
    JOBS_JOB_DETAILS_SEO_TITLE_TAG_LOGGED_IN: 'Job: {job_title}',
    JOBS_JOB_DETAILS_SEO_TITLE_TAG_LOGGED_OUT: '{job_title} in {work_location}',
    JOBS_JOB_DETAILS_SHARE_JOB: 'Share job ad',
    JOBS_JOB_DETAILS_SIMILAR_JOBS_HEADLINE: 'Similar jobs',
    JOBS_JOB_DETAILS_TEMPLATE_DATA_DESCRIPTION_VIDEO_NO_TRACKING_CONSENT_LINK:
      'Play video',
    JOBS_JOB_DETAILS_TEMPLATE_DATA_DESCRIPTION_VIDEO_NO_TRACKING_CONSENT_MESSAGE:
      "This video is served directly from an external website, and you've deactivated such content on XING in your settings (privacy > tracking).",
    JOBS_JOB_DETAILS_TITLEBAR_SUFFIX: 'XING Jobs',
    JOBS_JOB_DETAILS_TOPBAR_TITLE: 'Details',
    JOBS_JOB_DETAILS_UNFOLLOW_COMPANY: 'Unfollow',
    JOBS_JOB_DETAILS_XING_APPLY: 'Easy apply',
    JOBS_JOB_DETAILS_XING_APPLY_DEACTIVATED_ERROR:
      'This job has been deleted. Try searching for other suitable jobs now.',
    JOBS_JOB_DETAILS_XING_APPLY_TYPE_ERROR:
      "There's a new way to apply for this job. Please reload the page now.",
    JOBS_JOB_DETAILS_XING_VERIFIED: 'Verified job ad',
    JOBS_JOB_NOT_AVAILABLE_CTA: 'Search jobs now',
    JOBS_JOB_NOT_AVAILABLE_HEADLINE: "This job ad isn't available.",
    JOBS_JOB_NOT_AVAILABLE_SUBLINE:
      'Run a search to find lots of other great jobs just for you.',
    JOBS_JOB_TEASER_SAVE: 'Save job',
    JOBS_JOB_TEASER_SAVED: 'Saved',
    JOBS_MATCHING_REASONS_ERROR:
      'The AI couldn’t load the key matches between your search and the job description.',
    JOBS_MATCHING_REASONS_LOADING: 'Looking for key matches in the description',
    JOBS_MATCHING_REASONS_TIP_HEADLINE: 'See why a job matches your search',
    JOBS_MATCHING_REASONS_TIP_SUBTITLE: 'Beta AI feature for you',
    JOBS_MYJOBS_SAVED_SECTION_TITLE: 'Saved jobs',
    JOBS_NON_REMOTE_TAG: 'On-site',
    JOBS_REMOTE_TAG: 'Remote',
    JOBS_SALARY_XING_ESTIMATED: '(XING estimate)',
    JOBS_SEARCH_JOBS_LINK: 'All results',
    JOBS_TIMESTAMP_30_PLUS_DAYS_AGO: '30+ days ago',
    JOBS_TIMESTAMP_JUST_NOW: 'Just now',
    JOBS_TIMESTAMP_N_DAYS_AGO: '{number} days ago',
    JOBS_TIMESTAMP_N_HOURS_AGO:
      '{number, plural, =1 {1 hour ago} other {# hours ago}}',
    JOBS_TIMESTAMP_YESTERDAY: 'Yesterday',
    MESSENGER_SHARE_COMMENT_PLACEHOLDER: 'Comment on your link here.',
    MESSENGER_SHARE_MENU_ENTRY: 'Share',
    MESSENGER_SHARE_RECEIPENT_SELECTION_FORM_LABEL: 'Recipients:',
    MESSENGER_SHARE_RECEIPENT_SELECTION_PLACEHOLDER: 'Enter recipients',
    SHARE_BOX_PLACEHOLDER: 'Comment on your link here.',
    SHARE_BOX_SUBMIT_SHARE: 'Share',
    SHARE_CANCEL_BUTTON: 'Cancel',
    SHARE_CHAT_HINT: 'Your recipients will each receive a message.',
    SHARE_FEEDBACK_MESSENGER_BUTTON: 'View message',
    SHARE_FEEDBACK_MESSENGER_MESSAGE: '{item} shared via XING message',
    SHARE_FEEDBACK_POST_LABEL: 'Post',
    SHARE_FEEDBACK_STARTPAGE_BUTTON: 'View post',
    SHARE_FEEDBACK_STARTPAGE_MESSAGE: '{item} shared on the homepage',
    SHARE_FETCH_ERROR_RETRY: 'Try again',
    SHARE_PREVIEW_ERROR_HEADLINE: 'Preview currently unavailable.',
    SHARE_TO_MESSENGER_EMPTY_STATE:
      "Unfortunately there weren't any hits for {query}.",
    SHARE_USER_SUBTEXT_GROUPS: 'Share with a group',
    SHARE_USER_SUBTEXT_MESSAGE: 'Share in a chat message',
    SHARE_USER_SUBTEXT_STARTPAGE: 'Share on XING',
    XDS_EMPTYSTATE_ERROR_BUTTON: 'Try again',
    XDS_EMPTYSTATE_ERROR_DESCRIPTION: 'Please try again.',
    XDS_EMPTYSTATE_ERROR_TITLE: "That didn't work.",
  },
};
